import React from 'react';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import SvgCharts from '../svg/SvgCharts';

export default () => (
    <Layout>
        <div className="pt-18 md:pt-20">
        <h2 className="w-full my-0 text-4xl font-bold leading-tight text-center text-gray-800">About</h2>
        <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl font-semibold leading-tight">Time-based Analysis</h3>
            <p className="mt-5 text-xl font-light leading-relaxed">
              See when most of your power is used. Know how much its costing you.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
        />
        </div>
        
    </Layout>
);